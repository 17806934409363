.react-datepicker__navigation--previous, .react-datepicker__navigation--next {
  margin-top: 6px;
}

.react-datepicker {
  font-size: 1em;
} 


.react-datepicker__header {
  padding-top: 0.8em;
}

.react-datepicker__month {
   margin: 0.4em 1.4em;
}

.react-datepicker__navigation {
  top: 6px;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}

.react-datepicker__month-wrapper > .react-datepicker__month-text--keyboard-selected {
  background-color: #F1F1E8;
}

.react-datepicker__month-wrapper > .react-datepicker__month-text--keyboard-selected:hover {
  background-color: #E7E7D4 !important;
}

.react-datepicker__month--in-range {
  background-color: #F4C842 !important;
}

.react-datepicker__month--in-range:hover {
  background-color: #E7E7D4 !important;
}

.react-datepicker__day:active{
  background-color: #E7E7D4 !important;
}