@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Anybody', 'Manrope', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.box-shadow{
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

@font-face {
  font-family: 'Anybody';
  src: local('Anybody'), url('./assets/fonts/Anybody-Regular.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Anybody-Bold';
  src: local('Anybody'), url('./assets/fonts/Anybody-Bold.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('./assets/fonts/Manrope-Medium.woff2') format('woff2');
  font-style: normal;
}

@font-face {
  font-family: 'Manrope-Bold';
  src: local('Manrope-Bold'), url('./assets/fonts/Manrope-SemiBold.woff2') format('woff2');
  font-style: normal;
}

@layer base {
  .main-font {
    font-family: 'Anybody', sans-serif;
  }
  .main-font-bolded {
    font-family: 'Anybody-Bold', sans-serif;
  }

  .secondary-font {
    font-family: 'Manrope', sans-serif;
  }

  .secondary-font-bolded {
    font-family: 'Manrope-Bold', sans-serif;
  }
  .no-font-synthesis {
    font-synthesis: none;
  }
}

@layer components {
  .active-header-button {
    @apply border-Green border-b-[5px] main-font-bolded text-Black pb-[2px] text-[16px] leading-[24px]
  }
  .inactive-header-button {
    @apply border-transparent border-b-[5px] font-[400] text-Grey pb-[2px] text-[16px] leading-[24px] hover:text-Black tracking-[1.3px] hover:main-font-bolded hover:tracking-normal
  }
  .yellow-button {
    @apply border-Yellow text-Black bg-Yellow inline-flex justify-center items-center py-2 px-4 border-[1px] gap-[10px] shadow-[0_1px_2px_rgba(16,24,40,0.05)] text-[15px] main-font-bolded rounded-[8px] leading-[20px] cursor-pointer disabled:cursor-default disabled:opacity-[0.5] no-font-synthesis
  }

  .template-button {
    @apply border-Yellow text-Black bg-Yellow inline-flex justify-center items-center py-2 px-4 border-[1px] gap-[10px] shadow-[0_1px_2px_rgba(16,24,40,0.05)] text-[15px] rounded-[8px] leading-[20px] cursor-pointer disabled:cursor-default disabled:opacity-[0.5] no-font-synthesis
  }

  .green-button {
    @apply inline-flex justify-center items-center py-2 px-4 shadow-[0_1px_2px_rgba(16,24,40,0.05)] text-[15px] main-font-bolded rounded-[8px] leading-[20px] text-Black bg-Green border-[1px] border-Green disabled:cursor-default disabled:opacity-50 no-font-synthesis
  }

  .grey-button {
    @apply block xl:inline-flex justify-center text-center items-center py-2 px-2 xl:px-4 shadow-[0_1px_2px_rgba(16,24,40,0.05)] text-[15px] main-font-bolded rounded-[8px] leading-[20px] text-Black bg-[#f0f0e4] border-[1px] border-[#f0f0e4] disabled:cursor-default disabled:opacity-50 no-font-synthesis
  }
}

@layer utilities {
  .list-scrollbar {
    scrollbar-width: thin; /* for Firefox */
    scrollbar-color: #f4c842 #FCEFC6; /* for Firefox */
    overflow: auto;
  }
  .list-scrollbar::-webkit-scrollbar {
    width: 4px;
  }
  .list-scrollbar::-webkit-scrollbar-thumb {
    background-color: #f4c842 !important ;
    border-radius: 4px;
  }
  .list-scrollbar::-webkit-scrollbar-track {
    background-color: #FCEFC6 !important;
    border-radius: 4px;
  }
  .remove-focus:focus{
    outline: 0;
  }
  .remove-focus::-moz-focus-inner {
    outline: 0;
  }
  .error-scrollbar {
    scrollbar-width: thin; /* for Firefox */
    scrollbar-color: #ffffff #DA3838; /* for Firefox */
    overflow-y: auto;
  }
  .error-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  .error-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ffffff !important ;
    border-radius: 4px;
  }
  .error-scrollbar::-webkit-scrollbar-track {
    background-color: #DA3838 !important;
    border-radius: 4px;
  }
  .not-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
}